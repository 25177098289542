// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-index-tr-js": () => import("./../../src/pages/about/index.tr.js" /* webpackChunkName: "component---src-pages-about-index-tr-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-tr-js": () => import("./../../src/pages/index.tr.js" /* webpackChunkName: "component---src-pages-index-tr-js" */),
  "component---src-pages-photo-index-js": () => import("./../../src/pages/photo/index.js" /* webpackChunkName: "component---src-pages-photo-index-js" */),
  "component---src-pages-photo-index-tr-js": () => import("./../../src/pages/photo/index.tr.js" /* webpackChunkName: "component---src-pages-photo-index-tr-js" */),
  "component---src-pages-tr-404-js": () => import("./../../src/pages/tr/404.js" /* webpackChunkName: "component---src-pages-tr-404-js" */),
  "component---src-pages-works-index-js": () => import("./../../src/pages/works/index.js" /* webpackChunkName: "component---src-pages-works-index-js" */),
  "component---src-pages-works-index-tr-js": () => import("./../../src/pages/works/index.tr.js" /* webpackChunkName: "component---src-pages-works-index-tr-js" */),
  "component---src-templates-article-js": () => import("./../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-article-tr-js": () => import("./../../src/templates/article-tr.js" /* webpackChunkName: "component---src-templates-article-tr-js" */),
  "component---src-templates-category-js": () => import("./../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-category-tr-js": () => import("./../../src/templates/category-tr.js" /* webpackChunkName: "component---src-templates-category-tr-js" */)
}

