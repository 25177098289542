module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"neruia","short_name":"neruia","start_url":"/","lang":"en","description":"Neruia | Alperen KARLI","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/images/icon.png","localize":[{"start_url":"/tr/","lang":"tr","name":"neruia","short_name":"neruia","description":"Neruia | Alperen KARLI"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"32c71a009f531ea1dc8887914d991f41"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
