/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// trigger an immediate page refresh when an update is found
require('./src/assets/scss/main.scss');

exports.onServiceWorkerUpdateReady= () => window.location.reload(true);